import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import Hero from "common/hero";
import CategoryDetails from "common/category-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import heroImageRef from "images/face/skinagingfactor/Face-Web-Page-Banner-LightRays.webp";

import img2 from "images/callouts/eucerin-face-product-family.webp";
import img3 from "images/callouts/cout-footer-daily-skin.webp";
import img4 from "images/callouts/sunproducts2x.webp";

import productInfoIcon from "images/face/key-molecule-icon.svg";
import productInfoImg from "images/face/skinagingfactor/skin-aging.webp";

import immersiveHydrationProductImg from "images/face/skinagingfactor/immersive-hydration-product-lineup.webp";
import imgChart1 from "images/face/skinagingfactor/dermatological-benefits.webp";

import "./skinagingfactor.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
    return (
        <Layout
            pageClass="page-face-skinagingfactor page-level-3"
            breadcrumbData={pageContext.breadcrumb}
            hero={<MyHero />}
        >
            <Seo
                pageTitle="Skin Aging Factors"
                pageDescription=""
            />

            <div className="inner-body-content">
                <div className="top-content-level-3">
                    <section className="inner-centered-container">
                        <CategoryDetails
                            headBgColor="#6ACAD5"
                            headText="Skin Aging Factors"
                            infoText="Skin aging starts early"
                            bodyText="Skin aging can be impacted by lifestyle, biological, and environmental factors. These factors have a big impact:"
                            className="impacts-of-sunlight-div"
                            productInfoIcon={productInfoIcon}
                            productInfoIconAlt="Sun and shield graphic"
                            productInfoImg={productInfoImg}
                            productInfoImgAlt="Woman's face with sunlight streaks"
                            headContent={`<p class="font-semi-bold teal-text">Sun</p>
                            <p>UV exposure accounts for approximately 80% of facial skin aging.<sup>1</sup></p>

                            <p class="font-semi-bold teal-text">Biology</p>
                            <p>
                                Natural hyaluronic acid production in the skin decreases starting at age 20, and
                                by age 50, production is at 50%.<sup>2</sup>
                            </p>`}
                        >
                            <h2 className="teal-text">The key to smooth, healthy skin is hydration. A key molecule in retaining moisture is hyaluronic acid (HA).</h2>

                        </CategoryDetails>

                        <Row>
                            <Col xs={12} lg={8}>
                                <p className="references last">
                                    <span className="font-bold">References: 1.</span> Papakonstantinou E, Roth M, Karakiulakis G. Hyaluronic acid: a key molecule in aging. <em>Dermatoendocrinol</em>. 2012;4(3):253-258. <span className="font-bold"><br />2.</span> Goberdhan L, Makino E, Fleck T, Mehta R. Poster Abstract 3454: Immediate and long-term effects of a topical serum with five forms of hyaluronic acid on facial wrinkles and intrinsic skin moisture content.<em> JAAD. </em>2016;74 (5, Suppl 1):AB1-AB406.
                                </p>
                            </Col>
                        </Row>

                    </section>
                </div>

                <section className="gradient-bg">
                    <div className="inner-centered-container">
                        <AccordionContent
                            accordionHeading="Multi-weight hyaluronic acid and antioxidants"
                            id="multi-weightHA"
                        >
                            <Row>
                                <Col sm={12} md={8}>
                                    <img
                                        src={imgChart1}
                                        className="accordion-chart"
                                        alt="Illustration of High molecular weight, low molecular weight and antioxidants in the skin"
                                    />
                                </Col>
                            </Row>
                        </AccordionContent>
                    </div>
                </section>

                <section className="callouts-container gradient-bg section-immersive-hydration-technology">
                    <div className="inner-centered-container">
                        <Row>
                            <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }} className="">
                                <TypeC
                                    prodLabel={`FACE CARE`}
                                    prodLabelCss="h3-boxed lightDark-blue"
                                    buttonText="See TECHNOLOGY"
                                    buttonUrl="/face/immersivehydrationtechnology"
                                    className="center-v"
                                >
                                    <p className="reg-text-big reg-text-big-only">
                                        Eucerin Face, featuring <br className="show-desktop" />{" "}
                                        Immersive Hydration technology
                                    </p>
                                </TypeC>
                            </Col>
                            <Col sm={12} md={12} lg={6} order={{ xs: 1, lg: 2 }} className="">
                                <img
                                    src={immersiveHydrationProductImg}
                                    alt="Immersive Hydration product line with a red new flag"
                                    className="cout-product-img"
                                />
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="callouts-container gradient-bg blue-grad">
                    <div className="inner-centered-container">
                        <Row>
                            <Col sm={12} md={4}>
                                <TypeA
                                    imgRef={img2}
                                    imgAlt="Eucerin face product family"
                                    caption="Find resources to support your sun care recommendations"
                                    buttonText="Browse Resources"
                                    buttonUrl="/resources"
                                    imgStyles={{ height: 158 }}
                                />
                            </Col>

                            <Col sm={12} md={4}>
                                <TypeA
                                    imgRef={img3}
                                    imgAlt="Explore Body Care"
                                    caption="Clinically proven therapeutic solutions for daily skin care"
                                    buttonText="Explore Body Care"
                                    buttonUrl="/body"
                                    imgStyles={{ height: 165 }}
                                />
                            </Col>
                            <Col sm={12} md={4}>
                                <TypeA
                                    imgRef={img4}
                                    imgAlt="Explore Sun Care"
                                    caption="Sun protection for everyone <br /> under the skin"
                                    buttonText="Explore Sun Care"
                                    buttonUrl="/sun"
                                    imgStyles={{ height: 130 }}
                                />
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default IndexPage;
